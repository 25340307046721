import AppsIcon from '@2fd/ant-design-icons/lib/Login';
import { MenuOutlined } from '@ant-design/icons';
import { Button as BtnHospy, Icon } from '@hospy/hospy-ui';
import { LogoIcon } from '@hospy/shared-components';
import { useAppSelector } from '@hospy/store';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { Button, Divider, Dropdown, Layout, MenuProps } from 'antd';
import classNames from 'classnames';
import { forwardRef, useEffect, useRef, useState } from 'react';
//import { CircleFlag } from 'react-circle-flags';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import styled, { css, useTheme } from 'styled-components';
import Drawer from './animated-drawer';
import LanguageSelect from './language-select';
import './style.css';

const { Header: AntHeader } = Layout;

// type MenuItem = Required<MenuProps>['items'][number];

// function getItem(
// 	label: React.ReactNode,
// 	key: React.Key,
// 	icon?: React.ReactNode,
// 	children?: MenuItem[],
// 	type?: 'group'
// ): MenuItem {
// 	return {
// 		key,
// 		icon,
// 		children,
// 		label,
// 		type
// 	} as MenuItem;
// }

interface NavBarProps {
	style?: {
		container?: React.CSSProperties;
		nav?: React.CSSProperties;
	};
	renderMenuButton?: React.ReactElement;
	hideAddPropertyData?: boolean;
}

const NavBar = ({ style, hideAddPropertyData }: NavBarProps) => {
	const navigate = useNavigate();
	const theme: any = useTheme();
	//const { appName } = useAppSelector(({ user }) => user);
	const { isAuth } = useAppSelector(({ user }) => user);
	const { language } = useAppSelector(({ language }) => language);

	const [visible, setVisible] = useState(false);
	const toggleDrawer = (status: boolean) => {
		setVisible(status);
	};
	const location = useLocation();
	const [themeVariant, setThemeVariant] = useState<boolean>(false);

	const themeRoutes: any = [];

	useEffect(() => {
		const changeTheme = themeRoutes.includes(location.pathname);
		setThemeVariant(changeTheme);
	}, [location.pathname]);

	// useEffect(() => {
	// 	if (location.pathname === '/videos') {
	// 		return setThemeVariant(true);
	// 	}
	// 	setThemeVariant(false);
	// }, [location.pathname]);

	const BtnStart = ({
		showText = true,
		variant
	}: {
		showText?: boolean;
		variant?: boolean;
	}) => (
		<StartButton
			type="text"
			//shape="round"
			variant={variant}
			onClick={() => {
				window.location.href = process.env['NX_APP_START']
					? `${process.env['NX_APP_START']}?language=${language}`
					: `?language=${language}`;
			}}
			style={{
				justifyContent: 'center',
				borderRadius: '15px'
			}}
			icon={
				<StartButtonIcon
					variant={variant}
					style={{ fontSize: 20, position: 'relative', top: 1 }}
				/>
			}
		>
			{showText && <span>Ingresar</span>}
		</StartButton>
	);

	// const CountriesSelect = ({
	// 	showCountryName
	// }: {
	// 	showCountryName?: boolean;
	// }) => (
	// 	<Dropdown
	// 		trigger={['click']}
	// 		placement="bottomRight"
	// 		menu={{
	// 			selectable: true,
	// 			defaultSelectedKeys: ['co'],
	// 			selectedKeys: ['co'],
	// 			items: [
	// 				{
	// 					label: (
	// 						<span
	// 							style={{
	// 								display: 'flex',
	// 								padding: '10px',
	// 								alignItems: 'center',
	// 								fontSize: theme.text.size.middle
	// 							}}
	// 						>
	// 							<CircleFlag
	// 								countryCode="co"
	// 								width={20}
	// 								height={20}
	// 							/>{' '}
	// 							<span
	// 								style={{
	// 									marginLeft: 10,
	// 									flex: '1 auto'
	// 								}}
	// 							>
	// 								Colombia
	// 							</span>
	// 							<Icon
	// 								ant
	// 								material="verified"
	// 								style={{
	// 									marginLeft: 15,
	// 									fontSize: 15,
	// 									lineHeight: '1'
	// 								}}
	// 							/>
	// 						</span>
	// 					),
	// 					key: 'co'
	// 				},
	// 				{
	// 					label: (
	// 						<span
	// 							style={{
	// 								display: 'flex',
	// 								padding: '10px',
	// 								fontSize: theme.text.size.middle
	// 							}}
	// 						>
	// 							<CircleFlag
	// 								countryCode="mx"
	// 								width={20}
	// 								height={20}
	// 							/>{' '}
	// 							<span
	// 								style={{
	// 									marginLeft: 10
	// 								}}
	// 							>
	// 								México (pronto)
	// 							</span>
	// 						</span>
	// 					),
	// 					key: 'mx',
	// 					disabled: true
	// 				}
	// 			]
	// 		}}
	// 	>
	// 		<div
	// 			style={{
	// 				cursor: 'pointer',
	// 				display: 'flex',
	// 				justifyContent: 'center',
	// 				alignItems: 'center',
	// 				color:
	// 					themeVariant && !showCountryName
	// 						? 'white'
	// 						: theme.colors.black.base
	// 			}}
	// 		>
	// 			<CircleFlag countryCode="co" width={25} height={25} />
	// 			<span
	// 				style={{
	// 					display: showCountryName ? 'block' : 'none',
	// 					margin: '3px 0 0 3px'
	// 				}}
	// 			>
	// 				Colombia
	// 			</span>
	// 			<Icon
	// 				material="arrow_drop_down"
	// 				ant
	// 				style={{
	// 					marginLeft: 5,
	// 					fontSize: 18,
	// 					lineHeight: 1,
	// 					verticalAlign: 'middle'
	// 				}}
	// 			/>
	// 		</div>
	// 	</Dropdown>
	// );

	// const items = [
	// 	// getItem('Plataforma', 'sub1', null, [
	// 	// 	getItem(
	// 	// 		<a
	// 	// 			onClick={() => {
	// 	// 				toggleDrawer(false);
	// 	// 				navigate('/#checkin-checkout-automatica');
	// 	// 			}}
	// 	// 		>
	// 	// 			Check-in y Check-out automático
	// 	// 		</a>,
	// 	// 		'5'
	// 	// 	),
	// 	// 	getItem(
	// 	// 		<a
	// 	// 			onClick={() => {
	// 	// 				toggleDrawer(false);
	// 	// 				navigate('/#facturacion-electronica-automatica');
	// 	// 			}}
	// 	// 		>
	// 	// 			Facturación electrónica automática
	// 	// 		</a>,
	// 	// 		'6'
	// 	// 	),
	// 	// 	getItem(
	// 	// 		<a
	// 	// 			onClick={() => {
	// 	// 				toggleDrawer(false);
	// 	// 				navigate('/#conexion-servicios-locales');
	// 	// 			}}
	// 	// 		>
	// 	// 			Conexión con servicios locales
	// 	// 		</a>,
	// 	// 		'8'
	// 	// 	)
	// 	// ]),
	// 	// getItem(
	// 	// 	<a
	// 	// 		onClick={() => {
	// 	// 			toggleDrawer(false);
	// 	// 			navigate('/prices');
	// 	// 		}}
	// 	// 	>
	// 	// 		Precios
	// 	// 	</a>,
	// 	// 	'2'
	// 	// ),
	// 	// getItem(
	// 	// 	<a
	// 	// 		onClick={() => {
	// 	// 			toggleDrawer(false);
	// 	// 			window.open('https://help.hospy.co/', '_blank');
	// 	// 		}}
	// 	// 	>
	// 	// 		Centro de ayuda
	// 	// 	</a>,
	// 	// 	'3'
	// 	// )
	// ];

	// const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	// const handleResize = () => {
	// 	setWindowWidth(window.innerWidth);
	// };

	// useEffect(() => {
	// 	window.addEventListener('resize', handleResize);

	// 	return () => {
	// 		window.removeEventListener('resize', handleResize);
	// 	};
	// }, []);

	const divRef = useRef<HTMLDivElement>(null);
	//const [height, setHeight] = useState<number | null>(null);

	useEffect(() => {
		const handleHeightChange = () => {
			if (divRef.current) {
				//setHeight(divRef.current.clientHeight);
			}
		};

		handleHeightChange(); // Initial height measurement

		// Detect height changes when menu items change
		const menu = divRef.current?.querySelector('.ant-menu');
		if (menu) {
			menu.addEventListener('DOMNodeInserted', handleHeightChange);
			menu.addEventListener('DOMNodeRemoved', handleHeightChange);
		}

		return () => {
			if (menu) {
				menu.removeEventListener('DOMNodeInserted', handleHeightChange);
				menu.removeEventListener('DOMNodeRemoved', handleHeightChange);
			}
		};
	}, [divRef.current]);

	const NavButtons = () => {
		return (
			<NavButtonsContainer>
				<NavButton
					type="text"
					onClick={() => navigate('/')}
					htmlType="button"
					active={location.pathname === '/'}
				>
					<FormattedMessage
						id="home.nav.home"
						defaultMessage="Inicio"
					/>
				</NavButton>
				{/* <NavButton
					type="text"
					onClick={() => navigate('/#')}
					htmlType="button"
					active={location.pathname.startsWith('/#')}
				>
					<FormattedMessage
						id="home.nav.success-stories"
						defaultMessage="Casos de éxito"
					/>
				</NavButton> */}
				{/* <NavButton
					type="text"
					onClick={() => navigate('/videos')}
					htmlType="button"
					active={location.pathname.startsWith('/videos')}
				>
					<FormattedMessage
						id="home.nav.tutorials"
						defaultMessage="Tutoriales"
					/>
				</NavButton> */}
				<NavButton
					type="text"
					onClick={() => navigate('/prices')}
					htmlType="button"
					active={location.pathname.startsWith('/prices')}
				>
					<FormattedMessage
						id="home.nav.pricing"
						defaultMessage="Precios"
					/>
				</NavButton>
			</NavButtonsContainer>
		);
	};

	return (
		<>
			<Content style={{ ...style?.container }}>
				<Header style={{ ...style?.nav }}>
					<NavigationMenu.Root className="NavigationMenuRoot">
						<NavigationMenu.List className="NavigationMenuList">
							<NavigationMenu.Item className="print">
								<NavigationMenu.Link
									style={{ lineHeight: 1 }}
									onClick={() =>
										void navigate(`/`, { replace: true })
									}
								>
									<div className="logoContainer">
										{/* <HospyIcon
											className="logoMobile"
											color
											style={{ width: 30, height: 30 }}
										/> */}
										<LogoIcon
											// className="logoDesktop"
											theme={
												themeVariant ? 'dark' : 'light'
											}
											style={{
												cursor: 'pointer',
												position: 'relative',
												top: theme.screens.xs ? 0 : -7,
												maxWidth: 150
												// maxWidth: theme.screens.xs
												// 	? 80
												// 	: 150
											}}
										/>
									</div>
								</NavigationMenu.Link>
							</NavigationMenu.Item>

							<NavButtons />

							{/* <NavigationMenu.Item>
							<NavigationMenu.Trigger className="NavigationMenuTrigger">
								Plataforma{' '}
								<Icon
									material="arrow_drop_down"
									className="CaretDown"
									aria-hidden
								/>
							</NavigationMenu.Trigger>
							<NavigationMenu.Content className="NavigationMenuContent">
								<ul className="List one">
									<li style={{ gridRow: 'span 3' }}>
										<div className="PlatformInfoContainer">
											<div className="PlatformInfo">
												<h2
													style={{
														fontFamily:
															'InterRegularBold',
														fontWeight: 'bold'
													}}
												>
													Automatización y
													monetización
												</h2>
												<p>
													Automatizamos las tareas del
													front desk para que te
													enfoques en un servicio
													excelente.{' '}
												</p>
												<p>
													Nos conectamos con tus
													huéspedes y con proveedores
													de servicios locales para
													aumentar la monetización de
													tus propiedades.
												</p>
											</div>
										</div>
									</li>
									<ListItem
										icon={
											<span className="IconContainer">
												<IconItem material="task_alt" />
											</span>
										}
										title={
											<>
												Check-in y check-out automático{' '}
												<IconArrow material="arrow_forward_ios" />
											</>
										}
										onClick={() => {
											navigate(
												'/#checkin-checkout-automatica'
											);
										}}
									/>
									<ListItem
										icon={
											<span className="IconContainer">
												<IconItem materialOutlined="receipt_long" />
											</span>
										}
										title={
											<>
												Factura electrónica automática{' '}
												<IconArrow material="arrow_forward_ios" />
											</>
										}
										onClick={() => {
											navigate(
												'/#facturacion-electronica-automatica'
											);
										}}
									/>
									<ListItem
										icon={
											<span className="IconContainer">
												<IconItem material="store" />
											</span>
										}
										title={
											<>
												Conexión con servicios locales
												<IconArrow material="arrow_forward_ios" />
											</>
										}
										onClick={() => {
											navigate(
												'/#conexion-servicios-locales'
											);
										}}
									/>
								</ul>
							</NavigationMenu.Content>
						</NavigationMenu.Item> */}

							{/* <NavigationMenu.Item>
								<NavigationMenu.Link
									className={classNames(
										'NavigationMenuLink',
										{
											active: location.pathname.includes(
												'prices'
											)
										}
									)}
									onClick={() => void navigate('/prices')}
								>
									Precios
								</NavigationMenu.Link>
							</NavigationMenu.Item> */}

							{/* <NavigationMenu.Item>
							<NavigationMenu.Link
								className="NavigationMenuLink"
								href="https://help.hospy.co/es/"
								target="_blank"
							>
								Centro de ayuda
							</NavigationMenu.Link>
						</NavigationMenu.Item> */}

							<li style={{ flex: '1 auto' }} />

							<li className="countryOption">
								<LanguageSelect showLanguageLabel="code" />
								{/* <CountriesSelect /> */}
								{/* <Dropdown
								trigger={['click']}
								placement="bottomRight"
								menu={{
									selectable: true,
									defaultSelectedKeys: ['co'],
									selectedKeys: ['co'],
									items: [
										{
											label: (
												<span
													style={{
														display: 'flex',
														padding: '10px',
														alignItems: 'center',
														fontSize:
															theme.text.size
																.middle
													}}
												>
													<CircleFlag
														countryCode="co"
														width={20}
														height={20}
													/>{' '}
													<span
														style={{
															marginLeft: 10,
															flex: '1 auto'
														}}
													>
														Colombia
													</span>
													<Icon
														ant
														material="verified"
														style={{
															marginLeft: 15,
															fontSize: 15,
															lineHeight: '1'
														}}
													/>
												</span>
											),
											key: 'co'
										},
										{
											label: (
												<span
													style={{
														display: 'flex',
														padding: '10px',
														fontSize:
															theme.text.size
																.middle
													}}
												>
													<CircleFlag
														countryCode="mx"
														width={20}
														height={20}
													/>{' '}
													<span
														style={{
															marginLeft: 10
														}}
													>
														México (pronto)
													</span>
												</span>
											),
											key: 'mx',
											disabled: true
										}
									]
								}}
							>
								<div style={{ cursor: 'pointer', width: 48 }}>
									<CircleFlag
										countryCode="co"
										width={25}
										height={25}
									/>
									<Icon
										material="arrow_drop_down"
										ant
										style={{
											marginLeft: 5,
											fontSize: 18,
											lineHeight: 1,
											verticalAlign: 'middle'
										}}
									/>
								</div>
							</Dropdown> */}
							</li>

							<li
								style={{
									lineHeight: 1,
									display: 'flex',
									gap: 15
								}}
							>
								<ItemsRightContainer>
									{isAuth ? (
										<BtnStart variant={themeVariant} />
									) : (
										<>
											<BtnBegin
												variant={themeVariant}
												type="text"
												//shape="round"
												onClick={() => {
													window.location.href =
														process.env[
															'NX_APP_START'
														] + 'login';
												}}
												ghost
											>
												<FormattedMessage
													id="home.nav.login"
													defaultMessage="Iniciar sesión"
												/>
											</BtnBegin>

											<BtnTryNow
												type="primary"
												shape="round"
												href="https://hospy.typeform.com/to/fLDIZ7jy"
												target="_blank"
											>
												<span>
													<FormattedMessage
														id="home.nav.get-started"
														defaultMessage="Comenzar"
													/>
												</span>
											</BtnTryNow>
										</>
									)}
								</ItemsRightContainer>

								<div className="mobileMenuRight">
									{/* {!isAuth && (
									<BtnTryNow
										type="primary"
										shape="round"
										href="https://hospy.typeform.com/to/fLDIZ7jy"
										target="_blank"
									>
										<span>
											<FormattedMessage
												id="nav-bar.try-now"
												defaultMessage="Solicitar demo"
											/>
										</span>
									</BtnTryNow>
								)} */}

									{isAuth && <BtnStart showText={false} />}

									{!isAuth && (
										<Button
											style={{
												height: 50,
												width: 50,
												padding: 0
											}}
											type="link"
											icon={
												<MenuOutlined
													style={{ fontSize: 30 }}
												/>
											}
											onClick={() => toggleDrawer(true)}
										/>
									)}
								</div>
							</li>

							<NavigationMenu.Indicator className="NavigationMenuIndicator">
								<div className="Arrow" />
							</NavigationMenu.Indicator>
						</NavigationMenu.List>

						<div className="ViewportPosition">
							<NavigationMenu.Viewport className="NavigationMenuViewport" />
						</div>
					</NavigationMenu.Root>
				</Header>
			</Content>
			{/* Mobile options menu */}
			<Drawer visible={visible} onClose={() => toggleDrawer(false)}>
				<DrawerContent>
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
							marginBottom: '25px'
						}}
					>
						<Button
							type="text"
							icon={
								<Icon
									style={{ fontSize: 30 }}
									material="close"
									ant
								/>
							}
							onClick={() => toggleDrawer(false)}
						/>
					</div>

					{!isAuth && (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								width: '100%',
								justifyContent: 'center',
								alignContent: 'center',
								alignItems: 'center'
							}}
						>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									width: '100%',
									gap: 25,
									paddingInline: 15,
									maxWidth: 600,
									maxHeight: '90vh',
									overflow: 'auto'
								}}
							>
								<NavButtonMobile
									type="text"
									onClick={() => {
										navigate('/');
										toggleDrawer(false);
									}}
									htmlType="button"
									active={location.pathname === '/'}
								>
									<FormattedMessage
										id="home.nav.home"
										defaultMessage="Inicio"
									/>
								</NavButtonMobile>
								{/* <NavButtonMobile
									type="text"
									onClick={() => {
										navigate('/#');
										toggleDrawer(false);
									}}
									htmlType="button"
									active={location.pathname.startsWith('/#')}
								>
									<FormattedMessage
										id="home.nav.success-stories"
										defaultMessage="Casos de éxito"
									/>
								</NavButtonMobile> */}
								{/* <NavButtonMobile
									type="text"
									onClick={() => {
										navigate('/videos');
										toggleDrawer(false);
									}}
									htmlType="button"
									active={location.pathname.startsWith(
										'/videos'
									)}
								>
									<FormattedMessage
										id="home.nav.tutorials"
										defaultMessage="Tutoriales"
									/>
								</NavButtonMobile> */}
								<NavButtonMobile
									type="text"
									onClick={() => {
										navigate('/prices');
										toggleDrawer(false);
									}}
									htmlType="button"
									active={location.pathname.startsWith(
										'/prices'
									)}
								>
									<FormattedMessage
										id="home.nav.pricing"
										defaultMessage="Precios"
									/>
								</NavButtonMobile>
								<Divider style={{ padding: 0, margin: 0 }} />
								<BtnTryNow
									type="primary"
									shape="round"
									href="https://hospy.typeform.com/to/fLDIZ7jy"
									target="_blank"
								>
									<span>
										<FormattedMessage
											id="home.nav.get-started"
											defaultMessage="Comenzar"
										/>
									</span>
								</BtnTryNow>
								<BtnBegin
									type="text"
									shape="round"
									onClick={() => {
										window.location.href =
											process.env['NX_APP_START'] +
											'login';
									}}
								>
									<span>
										<FormattedMessage
											id="home.nav.login"
											defaultMessage="Iniciar sesión"
										/>
									</span>
								</BtnBegin>

								{/* <CountriesSelect showCountryName /> */}
								<LanguageSelect showLanguageLabel="name" />
							</div>
						</div>
					)}
				</DrawerContent>
			</Drawer>
		</>
	);
};

export default NavBar;

const DrawerContent = styled.div`
	margin-top: -15px;
	padding-inline: 15px;
`;

const ItemsRightContainer = styled.div`
	display: flex;
	gap: 15px;
	padding-left: 15px;
	margin-left: 15px;
	/* border-left: 1px solid ${({ theme }) => theme.colors.gray.base}; */

	@media only screen and (max-width: 1085px) {
		//test resolution
		display: none;
	}
`;

const ListItem = forwardRef(
	({ className, children, title, icon, ...props }: any, forwardedRef) => (
		<li>
			<NavigationMenu.Link asChild>
				<a
					className={classNames('ListItemLink', className)}
					{...props}
					ref={forwardedRef}
				>
					<div className="ListItemHeading">
						{icon}
						{title}
					</div>
				</a>
			</NavigationMenu.Link>
		</li>
	)
);

const IconArrow = styled(Icon)`
	line-height: 1;
	vertical-align: middle;
	margin-left: 5px;
`;

const IconItem = styled(Icon)`
	line-height: 1.2;
	vertical-align: middle;
	font-size: 25px;
`;

// const Drawer = styled(AntDrawer)`
// 	.ant-drawer-body {
// 		padding: 24px 0 !important;
// 	}
// `;

const BtnBegin = styled(Button)<{ variant?: boolean }>`
	&.ant-btn {
		font-size: 17px;
		min-width: 135px;
		height: 50px;
		border: 1px solid;
		border-color: ${({ theme }) => theme.colors.primary.base};
		background-color: ${({ theme }) => theme.colors.white.base};
		${({ theme }) =>
			theme.screens.xs &&
			css`
				min-width: 0px;
			`}
		box-sizing: border-box;
		border-radius: 15px;
	}
	&.ant-btn:hover:not(:disabled) {
		border-color: #0169f1c4;
	}
	&:hover {
		border: 1px solid ${({ theme }) => theme.colors.primary.base};
		color: ${({ theme }) => theme.colors.primary.base};
		background-color: ${({ theme }) => theme.colors.primary.lighten['90%']};
	}
	${({ variant }) =>
		variant &&
		css`
			color: ${({ theme }) => theme.colors.white.base};
			background-color: #293f79;
			border-color: #293f79 !important;
			&:hover {
				color: #e6e6e6;
				background-color: #1e2e57;
				border-color: #1e2e57 !important;
			}
		`}
`;

const BtnTryNow = styled(Button)`
	&.ant-btn {
		font-size: 17px;
		min-width: 135px;
		background-color: ${({ theme }) => theme.colors.primary.base};
		height: 50px;
		padding: 9px 21px !important;
		line-height: 1.5715 !important;
		box-sizing: border-box;
		border-radius: 15px;
	}
	&.ant-btn:hover:not(:disabled) {
		background-color: #0169f1c4;
	}
`;

const Header = styled(AntHeader)`
	display: flex;
	height: 80px;
	padding: 0rem 1.2rem;
	align-items: center;
`;

const Content = styled.div`
	position: fixed;
	border-bottom: 1px solid ${({ theme }) => theme.colors.gray.lighten['50%']};
	z-index: 3;
	width: 100%;
	&,
	& ${Header} {
		background-color: ${({ theme }) => theme.colors.containerBgColor};
	}

	@media print {
		box-shadow: none !important;
	}
`;

const StartButton = styled(BtnHospy)<{ variant?: boolean }>`
	border: 1px solid;
	border-color: ${({ theme }) => theme.colors.primary.base};
	display: flex;
	align-items: center;
	border-radius: 15px;
	padding: 11.4px 25px;
	height: auto !important;
	&:hover {
		border: 1px solid ${({ theme }) => theme.colors.primary.base};
		color: ${({ theme }) => theme.colors.primary.base};
		background-color: ${({ theme }) => theme.colors.primary.lighten['90%']};
	}
	${({ variant }) =>
		variant &&
		css`
			background-color: #293f79;
			border-color: #293f79 !important;
			&:hover {
				color: #e6e6e6;
				background-color: #1e2e57;
				border-color: #1e2e57 !important;
			}
			color: ${({ theme }) => theme.colors.white.base};
		`}
`;

const StartButtonIcon = styled(AppsIcon)<{ variant?: boolean }>`
	color: ${({ theme }) => theme.colors.primary.base};
	${({ variant }) =>
		variant &&
		css`
			color: ${({ theme }) => theme.colors.white.base};
		`}
`;
const NavButtonsContainer = styled.div`
	display: flex;
	gap: 15px;
	flex-direction: row;
	@media (max-width: 1085px) {
		//test resolution
		display: none;
	}
`;

const NavButton = styled(Button)<{ active?: boolean }>`
	display: flex;
	align-items: center;
	gap: 10px;
	font-size: 17px;
	margin-bottom: 30px;
	margin-top: 30px;
	border: none;
	border-radius: 15px;
	padding: 15px 25px;

	${({ active }) =>
		active &&
		css`
			background-color: #e2eeff;
			:hover {
				background-color: #d5e6ff;
			}
		`}
`;

const NavButtonMobile = styled(Button)<{ active?: boolean }>`
	&.ant-btn {
		font-size: 17px;
		min-width: 135px;
		height: 50px;
		border: none;
		${({ theme }) =>
			theme.screens.xs &&
			css`
				min-width: 0px;
			`}
		box-sizing: border-box;
		border-radius: 15px;
	}
	&.ant-btn:hover:not(:disabled) {
		border: none;
	}
	&:hover {
		border: none;
		color: ${({ theme }) => theme.colors.primary.base};
		background-color: ${({ theme }) => theme.colors.primary.lighten['90%']};
	}
	${({ active }) =>
		active &&
		css`
			background-color: #e2eeff;
			:hover {
				background-color: #d5e6ff;
			}
		`}
`;
